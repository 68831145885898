<template>
  <div class="advertisers-types">
    <div class="container">
      <h2 class="title">{{ $t("advertEffective") }}</h2>
      <div class="advertisers-types__items">
        <div
          class="advertisers-types__item"
          v-for="(advert, index) in $t('advertItems')"
          :key="index"
        >
          <div class="advertisers-types__name">{{ advert.title }}</div>
          <div class="advertisers-types__descr">{{ advert.Content }}</div>
          <img
            class="advertisers-types__img"
            :src="getImage(advert.image)"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvertisersTypes",
  methods: {
    getImage(image) {
      return require("@/assets/img/advertisers/" + image);
    },
  },
};
</script>
